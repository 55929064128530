import React, { useEffect } from 'react'

import canUseDOM from '../utils/canUseDom'

const useBodyLockWhenOverlayIsActive = (
  isActive,
  bodyClassName = 'no-scroll-and-hidden'
) => {
  let yOffset

  const lockBodyElement = () => {
    yOffset = window.pageYOffset

    if (canUseDOM) {
      document.body.classList.add(bodyClassName)
      document.body.style.top = `-${yOffset}px`
    }
  }

  const unlockBodyElement = () => {
    if (canUseDOM) {
      document.body.classList.remove(bodyClassName)

      window.scroll({ top: yOffset })

      yOffset = 0
    }
  }

  useEffect(() => {
    if (isActive) {
      lockBodyElement()
    } else {
      unlockBodyElement()
    }

    return () => unlockBodyElement()
  }, [isActive])
}

export default useBodyLockWhenOverlayIsActive
